<template>
    <LiefengContent>
        <template #title>{{ "" || "数据管理" }}</template>
        <template #toolsbarRight>
            <Form :label-colon="true" :inline="true" :label-width="60">
                <FormItem label="编号">
                    <Input type="text" v-model="search.keys" style="width: 180px"></Input>
                </FormItem>
                <FormItem label="姓名">
                    <Input type="text" v-model="search.keys" style="width: 180px"></Input>
                </FormItem>
                <FormItem label="手机号">
                    <Input type="text" v-model="search.keys" style="width: 180px"></Input>
                </FormItem>
                <Button style="margin-right: 10px" type="primary" icon="ios-search" @click="searchBtn">搜索</Button>
                <Button style="margin-right: 10px" type="success" icon="ios-refresh" @click="resetBtn">重置</Button>
                <Button style="margin-right: 10px" type="primary" icon="ios-add" @click="openAddBase">新增</Button>
                <Button style="margin-right: 10px" type="error" @click="openAddBase">导入</Button>
                <Button style="margin-right: 10px" type="error" @click="openAddBase">导出</Button>
            </Form>
        </template>
        <template #contentArea>
            <LiefengTable
                :talbeColumns="talbeColumns"
                :tableData="tableData"
                :loading="loading"
                :fixTable="true"
                :curPage="page"
                :total="total"
                :pagesizeOpts="[20, 30, 50, 100]"
                :page-size="pageSize"
                @hadlePageSize="hadlePageSize"
            ></LiefengTable>

            <LiefengModal :value="addBaseStatus" title="新增居民基础数据" width="600px" height="calc(100vh - 200px)" @input="changeAddBaseStatus">
                <template v-slot:contentarea>
                    <Baseform :formData="baseData" :resetNum="resetNum"></Baseform>
                </template>
                <template v-slot:toolsbar>
                    <Button style="margin-right: 10px" type="info" @click="cancelAddBase">取消</Button>
                    <Button type="primary" @click="saveAddBase">确定</Button>
                </template>
            </LiefengModal>
        </template>
    </LiefengContent>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"
import LiefengModal from "@/components/LiefengModal"
import Baseform from "./children/baseform.vue"
import Detailsfrom from "./children/DetailsFroms.vue"
export default {
    components: {
        LiefengContent,
        LiefengTable,
        LiefengModal,
        Baseform,
        Detailsfrom,
    },
    data() {
        return {
            search: {
                keys: "",
            },

            tableData: [],
            talbeColumns: [
                {
                    type: "selection",
                    width: 60,
                    align: "center",
                },
                {
                    title: "房号",
                    key: "houseNum",
                    width: 120,
                    align: "center",
                },
                {
                    title: "姓名",
                    key: "userName",
                    width: 120,
                    align: "center",
                },
                {
                    title: "联系电话",
                    width: 150,
                    align: "center",
                    render: (h, params) => {
                        return h("div", {}, params.row.mobileList && params.row.mobileList.length ? params.row.mobileList[0].mobile : "")
                    },
                },
                {
                    title: "证件号码",
                    key: "userName",
                    width: 200,
                    align: "center",
                    render: (h, params) => {
                        return h("div", {}, params.row.idNumList && params.row.idNumList.length ? params.row.idNumList[0].idNum : "")
                    },
                },
                {
                    title: "所在详细区域",
                    key: "houseAddr",
                    minWidth: 300,
                    align: "center",
                },
                {
                    title: "是否激活",
                    key: "activeStatus",
                    width: 120,
                    align: "center",
                },
                {
                    title: "来源",
                    key: "dataSource",
                    width: 150,
                    align: "center",
                },
                {
                    title: "数据状态",
                    key: "sex",
                    width: 120,
                    align: "center",
                    render: (h, params) => {
                        return h(
                            "div",
                            {
                                style: {
                                    cursor: params.row.updateStatus == "2" ? "pointer" : "auto",
                                    padding: "3px 5px",
                                    background: params.row.updateStatus == "2" ? "#f8c2c2" : "",
                                    borderRadius: "5px",
                                    color: params.row.updateStatus == "2" ? "red" : "#000",
                                },
                                on: {
                                    click: () => {
                                        if (params.row.updateStatus == "2") {
                                            this.$core.openLayerFrame(
                                                {
                                                    type: 2,
                                                    title: "",
                                                    content: `/page#/residentupdatastatus`,
                                                    area: ["80%", "80%"],
                                                },
                                                () => {}
                                            )
                                        }
                                    },
                                },
                            },
                            params.row.updateStatus == "2" ? "待更新" : "已更新"
                        )
                    },
                },
                {
                    title: "档案状态",
                    key: "archiveStatus",
                    width: 120,
                    align: "center",
                },
                {
                    fixed: "right",
                    title: "操作",
                    width: 190,
                    align: "center",
                    render: (h, params) => {
                        return h("div", [
                            h(
                                "Button",
                                {
                                    props: {
                                        type: "info",
                                        size: "small",
                                    },
                                    style: {
                                        marginRight: "10px",
                                    },
                                    nativeOn: {
                                        click: () => {
                                            this.getDetails(params.row)
                                        },
                                    },
                                },
                                "修改"
                            ),
                            h(
                                "Button",
                                {
                                    props: {
                                        type: "info",
                                        size: "small",
                                    },
                                    nativeOn: {
                                        click: () => {
                                            this.deleteUser(params.row)
                                        },
                                    },
                                },
                                "删除"
                            ),
                        ])
                    },
                },
            ],
            loading: false,
            pageSize: 20,
            total: 0,
            page: 1,

            //基础数据
            addBaseStatus: false,
            baseData: {},
            resetNum: 0,
        }
    },

    methods: {
        // 分页
        hadlePageSize(val) {
            this.page = val.page
            this.pageSize = val.pageSize
            this.getList()
        },
        // 勾选表格
        tableSelect(val) {
            console.log(val)
        },
        // 搜索
        searchBtn() {
            this.page = 1
            this.pageSize = 20
            this.getList()
        },
        // 重置
        resetBtn() {
            this.page = 1
            this.pageSize = 20
            this.search = {}
            this.getList()
        },

        // 获取数据
        getList() {
            this.loading = true
            this.$get("/syaa/api/syuser/pc/uaUserInfo/selectUserInfoPage", {
                // userName: this.search.userName,
                // mobile: this.search.mobile,
                // status: this.search.status == "全部" ? "" : this.search.status,
                ...this.search,
                communityCode: 440103006106,
                page: this.page,
                pageSize: this.pageSize,
            })
                .then(res => {
                    this.loading = false
                    if (res.code == 200 && res.dataList) {
                        this.tableData = res.dataList
                        this.tableData.map(item => {
                            item.updateStatus = 2
                        })
                        console.log(this.tableData)
                        this.total = res.maxCount
                    } else {
                        this.$Message.error({
                            content: res.desc,
                            background: true,
                        })
                    }
                })
                .catch(err => {
                    console.log(err)
                    this.$Message.error({
                        content: "数据获取失败",
                        background: true,
                    })
                })
        },
        //删除
        deleteUser() {
            this.$Modal.confirm({
                title: "温馨提示",
                content: "<p>删除后将看不到该数据，请谨慎操作，是否确认删除？</p>",
                onOk: () => {
                    this.$post("", {
                        id,
                    })
                        .then(res => {
                            if (res.code == 200) {
                                this.$Message.success({
                                    content: "删除成功",
                                    background: true,
                                })
                            } else {
                                this.$Message.error({
                                    content: res.desc,
                                    background: true,
                                })
                            }
                        })
                        .catch(err => {
                            console.log(err)
                            this.$Message.error({
                                content: "删除失败",
                                background: true,
                            })
                        })
                },
            })
        },
        // 基础数据状态框
        changeAddBaseStatus(val) {
            this.addBaseStatus = val
        },
        // 打开新增基础数据
        openAddBase() {
            this.addBaseStatus = true
        },
        // 取消基础数据编辑
        cancelAddBase() {
            this.$Modal.confirm({
                title: "温馨提示",
                content: "<p>您正在关闭此窗口，信息将不会保存，确定继续？</p>",
                onOk: () => {
                    this.baseData = {}
                    this.resetNum++
                    this.addBaseStatus = false
                },
            })
        },
        // 保存基础数据新增
        saveAddBase() {
            if (!baseData.name) {
                return this.$Message.warning({
                    content: "请输入姓名",
                    background: true,
                })
            } else if (!baseData.addr) {
                return this.$Message.warning({
                    content: "请选择地址",
                    background: true,
                })
            }
            this.$post(
                "",
                {
                    ...formData,
                },
                { "Content-Type": "application/json" }
            )
                .then(res => {
                    if (res.code == 200) {
                        this.$Message.success({
                            content: "新增成功",
                            background: true,
                        })
                        this.baseData = {}
                        this.resetNum++
                        this.addBaseStatus = false
                        this.getList()
                    } else {
                        this.$Message.error({
                            content: res.desc,
                            background: true,
                        })
                    }
                })
                .catch(err => {
                    console.log(err)
                    this.$Message.error({
                        content: "新增失败",
                        background: true,
                    })
                })
        },
        // 获取详情
        getDetails() {},
    },
    created() {
        this.getList()
    },
}
</script>

<style scoped lang="less">
/deep/.ivu-drawer-body {
    .ivu-form-item {
        margin-bottom: 10px;
    }
    .demo-drawer-footer {
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        border-top: 1px solid #e8e8e8;
        padding: 10px 16px;
        text-align: right;
        background: #fff;
    }
}
/deep/#modal_contentarea {
    //     height: calc(100% - 107px) !important;
    overflow-y: hidden !important;
}
// /deep/#title{
//     padding-bottom: 10px !important;
// }
</style>
